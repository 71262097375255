@import url('https://fonts.googleapis.com/css?family=Exo:400,700');
.vanta-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  .parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    color: white;
    width: 100%;
  }
  
  .parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 64px;
@media (max-width: 768px) {
      font-size: 44px;
    }
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  
  .parallax span {
    display: block;
    margin-right: 30px;
  }